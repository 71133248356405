@import "@unioncredit/ui/src/variables";

.AccountModal {
  &__Buttons {
    & > a,
    & > button {
      width: 100%;
      flex: 0 50%;
      margin-right: 10px;

      @media screen and (max-width: $breakpoint-micro) {
        max-height: 40px !important;
        margin-top: 8px !important;
      }
    }

    @media screen and (max-width: $breakpoint-micro) {
      flex-direction: column !important;
    }
  }

  .WalletActivity {
    &__row {
      margin: 5px 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      margin-right: 4px;
    }

    &__value svg path {
      fill: $grey400;
    }
  }
}
