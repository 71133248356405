@import "@unioncredit/ui/src/variables";

.InstallAppDrawer {
  &__trigger {
    @media all and (min-width: $breakpoint-mobile) {
      display: none;
    }

    button {
      background: #2A2736;
    }

    path {
      fill: white;
    }
  }
}