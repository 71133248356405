@import "@unioncredit/ui/src/variables.scss";

.Header__Box--loading {
  .nav-item {
    visibility: hidden;
  }
}

.UnionWallet.button {
  height: 40px;
  min-height: 0;
  padding: 8px;
  color: $grey600;
  font-size: 14px !important;
}

.Header {
  width: 100%;
  box-sizing: border-box;

  .avatar {
    background-color: transparent;
  }

  .UnionWallet.button,
  .wallet.button,
  .Header__context-menu .PopoverMenu__button,
  .Header__hamburger {
    margin-left: 4px;
  }

  .grid-row {
    margin: 0 !important;
  }

  .UnionWallet.button,
  .wallet.button {
    background: $grey50;
    border-radius: 8px;

    &:hover {
      background: $grey100;
    }
  }
  .wallet.button {
    @media screen and (max-width: $breakpoint-mobile) {
      width: 40px;
    }
  }
}

.Header__context-menu .PopoverMenu__button,
.Header__hamburger {
  width: 40px !important;
  height: 40px;
  min-height: 0;
  border-radius: 8px;
}
.Header__hamburger {
  padding: 11px !important;
  background: $grey50 !important;

  svg {
    height: 24px;
  }

  &.active {
    padding: 8px !important;
  }
}

.Header__context-menu {
  z-index: 1000;

  .PopoverMenu__button {
    border: 1px solid $grey200;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 8px;

    &--open,
    &:hover {
      background-color: $grey100;
    }
  }
}

.toggle-label {
  font-weight: 500;
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 0;

  &--color {
    &-secondary {
      color: $grey600;
    }
  }
}

.PopoverMenu__after {
  border-top: 1px solid $grey100;
}
