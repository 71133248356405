@import "@unioncredit/ui/src/variables";

.ProfileVoucherStats {
  padding: 24px 0;

  @media screen and (max-width: 680px) {
    background: white;
    border: 0.5px solid #e4e4e7;
    border-radius: 20px;
    margin-bottom: 24px;
    padding: 18px;
  }

  &__TopVouchers {
    @media screen and (max-width: 680px) {
      flex-wrap: wrap;
      flex-direction: row !important;
      margin-bottom: -8px;
    }

    & > a {
      width: 100%;

      @media screen and (max-width: 680px) {
        display: block;
        flex: 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin-right: 12px;

        &:nth-of-type(2n) {
          margin-right: 0 !important;
        }
      }
    }
  }
  &__TopVoucher {
    padding: 8px 0;
    border-top: 1px solid #f1f1f1;

    @media screen and (max-width: 680px) {
      padding-left: 0;
      border-top: none;
      margin-bottom: 8px;
    }

    .avatar {
      @media screen and (max-width: 400px) {
        width: 34px !important;
        height: 34px !important;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
    &:last-of-type {
      border-bottom: 1px solid #f1f1f1;
    }
  }
  &__arrow {
    padding: 8px;
    background: #F4F4F5;
    border: 1px solid #E4E4E7;
    border-radius: 50%;

    @media screen and (max-width: 680px) {
      display: none;
    }
  }
}