@import "@unioncredit/ui/src/variables";

.Register {
  margin-top: 80px;
  max-width: 960px;

  @media all and (max-width: 767px) {
    margin-top: 40px;
  }

  &__container {
    padding: 1px;
    background: #f8fafc;
    border: 0.5px solid $grey200;
    border-radius: 8px;

    p {
      color: #64748b;
      margin: 0 8px 0 12px;
      font-size: 16px;
    }

    .networkSwitcher__buttonContainer {
      width: auto;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    background: white;
    padding: 3px;
    border-radius: 50%;
    margin: 0 8px 0 0;

    svg {
      height: 100%;
    }
  }

  &__mogo {
    font-size: 20px;
    padding: 14px 16px;
    color: $blue600;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 24px;
    font-weight: 600;
  }

  &__benefits {
    li {
      display: flex;
      align-items: center;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;

      path {
        fill: green;
      }
    }
  }

  .Card__footer {
    background: #fafafa;

    svg {
      opacity: 70%;
      width: 28px;
      height: 28px;
    }
    h2 {
      font-size: 16px;
    }
    li {
      margin: 8px 0;
      opacity: 80%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}