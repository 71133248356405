@import "@unioncredit/ui/src/variables";

.ProfileHeader {
  padding: 18px;
  overflow: visible !important;

  @media screen and (max-width: 670px) {
    flex-direction: column !important;
  }

  &__header {
    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column !important;

      .ProfileCtaButtons {
        display: none;
      }
    }
  }

  &__NetworkSelect {
    position: absolute;
    right: 0;
    bottom: -5px;

    @media screen and (max-width: $breakpoint-mobile) {
      //bottom: 0;
      right: 50%;
      transform: translateX(50%);
      z-index: 1;
      width: 51px;
    }

    .select-ui {
      width: auto !important;
      outline: none !important;

      .select-control {
        min-height: 31px;
        border-radius: 20px;
        padding: 0 1px;

        & + div {
          z-index: 100;
        }

        & > .avatar + div {
          padding: 0;
        }

        .dropdown-indicator {
          margin: 2px 8px 0 4px;
        }
      }
      .select-option {
        padding: 6px 3px;
      }
    }
  }

  &__avatar {
    position: relative;

    & > .avatar {
      border-color: transparent;

      @media screen and (max-width: $breakpoint-mobile) {
        width: 80px !important;
        height: 80px !important;
        margin-bottom: 16px;
      }
    }
  }

  &__content {
    padding-left: 16px;

    @media screen and (max-width: 670px) {
      padding-left: 8px;
    }

    .heading {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__address {
    .address {
      cursor: pointer;
    }
    a {
      margin-top: 2px;
    }
    .badge {
      border: 1px solid #F4F4F5 !important;
    }
    .badge, .BadgeIndicator {
      @media screen and (max-width: 400px) {
        font-size: 12px;
      }
    }
  }
}