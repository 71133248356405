.VouchLinkModal {
  overflow: visible;

  .Card__body {
    overflow: visible;
  }

  &__social {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}