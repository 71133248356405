@import "@unioncredit/ui/src/variables";

.WelcomeModal {
  background: $blue500;
  border-color: $blue500;

  &__content {
    text-align: center;

    @media all and (max-width: 460px) {
      padding: 12px 0 !important;
    }
  }
  &__button {
    background: $blue600 !important;

    @media all and (max-width: 460px) {
      margin-bottom: 8px;
    }
    @media all and (min-width: 460px) {
      &:hover {
        background: $blue400 !important;
      }
    }
    &:last-of-type {
      margin-left: 4px;
    }
  }
  .ShareButtons {
    @media all and (max-width: 460px) {
      flex-direction: column !important;
    }
  }
}